import React, { useEffect } from "react";
import Header from "./HeaderComponent";
import "../assets/css/careers.css";
import FooterComponent from "./FooterComponent";

/**
  Shows Creers page
*/
function CareersComponent() {
  useEffect(() => {
    const scroll_to = document.getElementById("careers_section");
    if (scroll_to) {
      scroll_to.scrollIntoView({ behavior: "smooth" });
    }
  }, []);
  return (
    <div id="careers_section">
        <div className="container" >
                <div id="careers_header">
                    <Header />
                </div>
                <div id="careers_container" >
                <div id="careers_image_section">
                        <img  className="carres_image careers_web_view_img" src="https://pub-assets.aurm.in/careersimg.png" alt="careers" />
                        <img className="mobile_view_img d-none" src="https://pub-assets.aurm.in/careers_mobile_img.png" alt="careers"/>
                    <div className="careers_img_overlay"></div>
                </div>
                <div id="careers_intro_content" className="text-center">
                  {/* <div className="text-center careers_div" id="careers_head"> */}
                      <div id="careers_main_head" className="text-center mt-4">Careers</div>
                      <div id="careers_intro" className="text-center">
                      At Aurm, we create secure, convenient, and joyous experiences for safeguarding wealth. Join our vibrant culture of teamwork, growth, and fun. Let’s achieve something extraordinary together!
                      </div>
                  {/* </div> */}
                  <div id="careers_view_all_btn_div" className="mt-3 text-center">
                    <button className="careers_view_all_btn text-white">
                        View all openings
                    </button>
                  </div>
                </div>
                </div>
            </div>
        <div className="careers_footer" id="careers_footer">
            <FooterComponent />
        </div>
    </div>
  );
}

export default CareersComponent;
