import React, {useEffect, useState } from "react";
import "../assets/css/header.css";
import brand_logo from "../assets/images/Aurm_Logo.svg";
import close_icon from "../assets/images/nav_menu_close_icon.svg";
import { scroller } from "react-scroll";
import { Link } from "react-router-dom";
const view_all_blogs_url = process.env.REACT_APP_BLOGS_URL;

/**
  Shows the NavBar Header.
*/
function HeaderComponent() {
  const scrollToSection = (sectionName) => {
    const scroller_section_tag = document.getElementById(sectionName);
    if (scroller_section_tag) {
      scroller.scrollTo(sectionName, {
        duration: 50,
        smooth: true,
      });
    }
  };

  const [isOpen, setIsOpen] = useState(false);
  const [isScrolled, setIsScrolled] = useState(false);

  const toggleNavbar = () => {
    setIsOpen(!isOpen);
  };

  useEffect(() => {
    const handleScroll = () => {
      const scrollY = window.scrollY;
      setIsScrolled(scrollY > 0);
    };
    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <section id="header_section">
      <header id="header_container" className={isScrolled ? "fixed" : ""}>
        <nav
          className={`container navbar navbar-expand-md nav_container_div ${
            isOpen ? "open" : ""
          }`}
          id="header_nav_container"
        >
          <a className="navbar_brand" href="/">
            <img className="aurm_logo" src={brand_logo} alt="aurm" />
          </a>
          <button id="navbar_toggler_btn" type="button" onClick={toggleNavbar}>
            {isOpen ? (
              <div className="custom_toggler_close_icon">
                <img src={close_icon} alt="close-icon" />
              </div>
            ) : (
              <>
                <span className="custom_toggler_icon d-block"></span>
                <span className="custom_toggler_icon d-block"></span>
                <span className="custom_toggler_icon d-block"></span>
              </>
            )}
          </button>
          <div
            className={`collapse navbar-collapse justify-content-end navigation-actions navbar_collapsed ${
              isOpen ? "show" : ""
            }`}
          >
            <ul className="navbar-nav navbar_list">
              <li className="nav-item d-none" id="team_nav_option">
                <Link
                  to="/"
                  onClick={() => {
                    scrollToSection("team_section");
                    localStorage.setItem("scrollToSection", "team_section");
                  }}
                >
                  <div className="nav_action_buttons overflow-hidden">
                    <div className="button_spacing">
                      <div className="nav_options">The Team</div>
                    </div>
                  </div>
                </Link>
              </li>
              <li className="nav-item" id="ourstory_nav_option">
                <Link to="/our_story">
                  <div className="nav_action_buttons overflow-hidden">
                    <div className="button_spacing">
                      <div className="nav_options">Our Story</div>
                    </div>
                  </div>
                </Link>
              </li>
              <li className="nav-item" id="carrers_nav_option">
                <Link to="/"
                  onClick={() => {
                    scrollToSection("team_section");
                    localStorage.setItem("scrollToSection", "team_section");
                  }}>
                  <div className="nav_action_buttons overflow-hidden">
                    <div className="button_spacing">
                      <div className="nav_options">Our Team</div>
                    </div>
                  </div>
                </Link>
              </li>
              <li className="nav-item" id="blogs_nav_option">
              <a href={`${view_all_blogs_url}`}>
                  <div className="nav_action_buttons overflow-hidden">
                    <div className="button_spacing">
                      <div className="nav_options">Blog</div>
                    </div>
                  </div>
                </a>
              </li>
              <li className="nav-item" id="contact_or_book_demo_nav_option">
                <Link
                  to="/"
                  onClick={() => {
                    scrollToSection("contact_section");
                    localStorage.setItem("scrollToSection", "contact_section");
                  }}
                >
                  <div className="nav_action_buttons overflow-hidden">
                    <div className="button_spacing">
                      <div className="nav_options">Contact us</div>
                    </div>
                  </div>
                </Link>
              </li>
              <li className="nav-item" id="careers_nav_option">
                <Link to="/careers">
                  <div className="nav_action_buttons overflow-hidden">
                    <div className="button_spacing">
                      <div className="nav_options">Careers</div>
                    </div>
                  </div>
                </Link>
              </li>
              <li
                className="nav-item d-none ms-3 mobile_head_option"
                id="get_in_touch_mobile_btn"
              >
                <Link
                  to="/"
                  onClick={() => {
                    scrollToSection("contact_section");
                    localStorage.setItem("scrollToSection", "contact_section");
                  }}
                >
                  <div className="mobile_touch_btn_div overflow-hidden">
                    <div className="touch_button_spacing">
                      <div className="nav_options text-truncate">
                        Get in Touch
                      </div>
                    </div>
                  </div>
                </Link>
              </li>
              <div className="d-none mobile_head_option" id="heads_footer">
                <div className="head_footer_item">
                  <a className="text-decoration-none" href="/">
                    <div className="head_footer_link_text">Cookie Settings</div>
                  </a>
                </div>
                <div className="head_footer_item">
                  <a className="text-decoration-none" href="/">
                    <div className="head_footer_link_text">Careers</div>
                  </a>
                </div>
                <div className="head_footer_item pb-0">
                  <a className="text-decoration-none" href="/">
                    <div className="head_footer_link_text">Legal</div>
                  </a>
                </div>
              </div>
            </ul>
          </div>
        </nav>
      </header>
    </section>
  );
}
export default HeaderComponent;